import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import useEffect from 'react'

const VideoGalleryPage = (props) => {
  
    const { data, location } = props
    const page = data.prismicVideoGallery.data
    
    ///only allow one video to play at once
    React.useEffect(()=> {
      const videos = document.getElementsByTagName("video");
      for (let video of videos) {
        video.addEventListener('play', e => {
          document.querySelectorAll('video').forEach(vid => {
            if(vid != e.target){
              vid.pause()
            }
          });
        })
      }
    },[]);
    

    return (
      <Layout>
        <SEO
          title={page.meta_title}
          description={page.meta_description}
          location={location}
        />
        <div className="content mb-10">
          <div className="pt-32 lg:pt-36 mb-0 lg:mb-0">
            <div className="relative">
              <div className="grid">
                <div className="grid__full ">
                  <div className="w-full type-lg lg:type-3xl text-white pb-10">
                    <div dangerouslySetInnerHTML={{ __html: page.page_title.text }} />
                  </div>
                  <div className="w-full pb-8 rte">
                    <div dangerouslySetInnerHTML={{ __html: page.page_content.html }} />
                  </div>
                  <div className="video-container">
                    {page.videos.map( ((video, index) => {

                      return (
  
                        <div key={index}>
                          <video className="focus:outline-none" controls={true} poster={video.video_poster_image.url}>
                            <source src={video.video_link.url}></source>
                          </video>
                          <p className="pt-2">{video.video_caption}</p>
                        </div>
                     
                        )
                    }))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
  
}

VideoGalleryPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageVideoGalleryQuery = graphql`
{
  prismicVideoGallery{
    data{
        meta_title
        meta_description
        canonical        
        page_title{
          text
        }
        page_content{
          html
        }
        videos{
          video_title,
          video_caption
          video_poster_image{
            url
          }
          video_link{
            url
          }
          
        }  
      }
  }
}
`

export default VideoGalleryPage
